/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {

  padding: 40px 0 20px 0;

  .entry {

    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

    .entry-img {
      max-height: 440px;
      margin: -30px -30px 20px -30px;
      overflow: hidden;
    }

    .entry-title {
      font-size: 28px;
      font-weight: bold;
      padding: 0;
      margin: 0 0 20px 0;

      a {
        color: $default;
        transition: 0.3s;
        &:hover {
          color: $primary;
        }

        //border-bottom: #ff560d 3px solid;
        padding-bottom: 0.2rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;

      }

    }

    .entry-meta {
      margin-bottom: 15px;
      color: lighten($secondary, 40);
      
      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;
        padding: 0;
        margin: 0;
    
        li + li {
          padding-left: 20px;
        }
      }
      i {
        font-size: 16px;
        margin-right: 8px;
        line-height: 0;
      }

      a {
        color: lighten($default, 20);
        font-size: 14px;
        display: inline-block;
        line-height: 1;
      }
    }

    .entry-content {
      p {
        line-height: 24px;
      }

      .read-more {
        text-align-last: right;
        a {
          display: inline-block;
          background: $primary;
          color: $white;
          padding: 6px 20px;
          transition: 0.3s;
          font-size: 14px;
          border-radius: 4px;
          &:hover {
            background: lighten($primary, 5);
          }
        }
      }

      h3 {
        font-size: 22px;
        margin-top: 30px;
        font-weight: bold;
      }

      
      blockquote {
        overflow: hidden;
        background-color: #fafafa;
        padding: 60px;
        position: relative;
        text-align: center;
        margin: 20px 0;

        p {
          color: $default;
          line-height: 1.6;
          margin-bottom: 0;
          font-style: italic;
          font-weight: 500;
          font-size: 22px;
        }

        &::after  {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 3px;
          background-color: $secondary;
          margin-top: 20px;
          margin-bottom: 20px;
        }

      }


    }
    
    .entry-footer {
      padding-top: 10px;
      border-top: 1px solid #e6e6e6;

      i {
        color: lighten($secondary, 30);
        display: inline;
      }

      a {
        color: lighten($secondary, 5);
        transition: 0.3s;
        &:hover {
          color: $primary;
        }
      }

      .cats {
        list-style: none;
        display: inline;
        padding: 0 20px 0 0;
        font-size: 14px;
        li {
          display: inline-block;
        }
      }

      .tags {
        list-style: none;
        display: inline;
        padding: 0;
        font-size: 14px;

        li {
          display: inline-block;
        }

        li + li::before {
          padding-right: 6px;
          color: #6c757d;
          content: ",";
        }
      }

      .share {
        font-size: 16px;
        i {
          padding-left: 5px;
        }
      }


    }

  }

  .entry-single {
    margin-bottom: 30px;
  }

  .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

    img {
      width: 120px;
      margin-right: 20px;
    }

    h4 {
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 0px;
      padding: 0;
      color: $secondary;
    }

    .social-links {
      margin: 0 10px 10px 0;
      a {
        color: rgba($secondary, .5);
        margin-right: 5px;
      }
    }

    p {
      font-style: italic;
      color: lighten($default, 45);
    }
  }

  .blog-comments {
    margin-bottom: 30px;

    .comments-count {
      font-weight: bold;
    }

    .comment {
      margin-top: 30px;

      position: relative;

      .comment-img {
        margin-right: 14px;
        img {
          width: 60px;
        }
      }

      h5 {
        font-size: 16px;
        margin-bottom: 2px;
        a {
          font-weight: bold;
          color: $default;
          transition: 0.3s;
          &:hover {
            color: $primary;
          }
        }

        .reply {
          padding-left: 10px;
          color: $secondary;
          i {
            font-size: 20px;
          }
        }

      }

      time {
        display: block;
        font-size: 14px;
        color: lighten($secondary, 10);
        margin-bottom: 5px;
      }


      &.comment-reply {
        padding-left: 40px;
      }
    }

    .reply-form {
      margin-top: 30px;
      padding: 30px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      h4 {
        font-weight: bold;
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }

      input {
        border-radius: 4px;
        padding: 10px 10px;
        font-size: 14px;
        &:focus {
          box-shadow: none;
          border-color: lighten($primary, 20);
        }
      }

      textarea {
        border-radius: 4px;
        padding: 10px 10px;
        font-size: 14px;
        &:focus {
          box-shadow: none;
          border-color: lighten($primary, 20);
        }
      }

      .form-group {
        margin-bottom: 25px;
      }

      .btn-primary {
        border-radius: 4px;
        padding: 10px 20px;
        border: 0;
        background-color: $secondary;
        &:hover {
          background-color: lighten($secondary, 5);
        }
      }
    }
  }

  .blog-pagination {

    color: lighten($secondary, 20);

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      margin: 0 5px;
      transition: 0.3s;

      a {
        color: $secondary;
        padding: 7px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active, &:hover {
        background: $primary;
        a {
          color: $white;
        }
      }
    }

  }

  .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

    .sidebar-title {
      font-size: 20px;
      font-weight: 700;
      padding: 0 0 0 0;
      margin: 0 0 15px 0;
      color: $secondary;
      position: relative;
    }

    .sidebar-item {
      margin-bottom: 30px;
    }

    .search-form {
      form {
        background: #fff;
        border: 1px solid #ddd;
        padding: 3px 10px;
        position: relative;

        input[type="text"] {
          border: 0;
          padding: 4px;
          border-radius: 4px;
          width: calc(100% - 40px);
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          border: 0;
          background: none;
          font-size: 16px;
          padding: 0 15px;
          margin: -1px;
          background: $primary;
          color: $white;
          transition: 0.3s;
          border-radius: 0 4px 4px 0;
          line-height: 0;
          i {
            line-height: 0;
          }

          &:hover {
            background: lighten($primary, 4);
          }
        }
      }
    }

    .categories {
      ul {
        list-style: none;
        padding: 0;
        li + li {
          padding-top: 10px;
        }

        a {
          color: $secondary;
          transition: 0.3s;
          &:hover {
            color: $primary;
          }
          span {
            padding-left: 5px;
            color: lighten($default, 40);
            font-size: 14px;
          }
        }
      }
    }

    .recent-posts {
      .post-item + .post-item {
        margin-top: 15px;
      }
      
      img {
        width: 80px;
        float: left;
      }
      
      h4 {
        font-size: 15px;
        margin-left: 95px;
        font-weight: bold;
        a {
          color: $secondary;
          transition: 0.3s;
          &:hover {
            color: $primary;
          }
        }
      }

      time {
        display: block;
        margin-left: 95px;
        font-style: italic;
        font-size: 14px;
        color: lighten($default, 40);
      }

    }

    .tags {
      margin-bottom: -10px;
      ul {
        list-style: none;
        padding: 0;
        li {
          display: inline-block;
        }

        a {
          color: lighten($secondary, 25);
          font-size: 14px;
          padding: 6px 14px;
          margin: 0 6px 8px 0;
          border: 1px solid lighten($secondary, 70);
          display: inline-block;
          transition: 0.3s;
          &:hover {
            color: $white;
            border: 1px solid $primary;
            background: $primary;
          }
          span {
            padding-left: 5px;
            color: lighten($secondary, 60);
            font-size: 14px;
          }
        }
      }
    }

  }

}
